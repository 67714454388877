@import "../variables.scss";

#menu-mobile {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.178);
  transition: all 0.2s;
  animation: appear 0.5s ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
  }
}

#menu-mobile-content {
  position: absolute;
  height: 428px;
  margin: 0 auto;
  width: 95%;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  max-width: 450px;

  background-color: $neutralColor-000;
  animation: slide 0.6s ease-in-out;

  @keyframes slide {
    from {
      top: -100%;
    }
  }

  .languages-menu-mobile {
    padding-top: 15px;
    list-style: none;
    text-align: left;

    li {
      a {
        font-size: 19px;
        text-align: left;
      }
    }
  }

  .item-hamburger {
    padding: 18px 0px;
    width: 100%;

    a {
      width: 100%;
      text-align: left;
      font-size: 19px;
      color: black;
    }
  }

  .item-hamburger:nth-child(2) {
    padding-top: 5px;
  }

  .languages-container {
    display: flex;
    justify-content: left;

    li {
      padding-bottom: 15px;

      a {
        display: flex;
        align-items: center;

        img {
          width: 19px !important;
          height: 14px !important;
        }

        span {
          color: black;
          font-size: 19px;
        }
      }
    }
  }

  .item-close {
    justify-content: flex-end;
    padding: 5px 0;
  }

  #menu-mobile-close {
    cursor: pointer;
    border: 0;
    border-radius: 10px;
    font-size: 19px;
    transition: all 0.2s;
    color: $blue;
    background-color: transparent;
  }
}

#home {
  height: 100vh;

  .container {
    max-width: 1320px;

    @media (min-width: 1320px) {
      margin: 0 auto;
    }
  }
}

.banner {
  &-title {
    color: $secondaryColor-700;
    text-align: center;

    @media (max-width: 830px) {
      font-size: 32pt;
    }

    @media (max-width: 350px) {
      font-size: 20pt;
    }
  }

  &-text {
    margin: 32px 0 77px;
    color: $neutralColor-500;
    text-align: center;

    @media (max-width: 620px) {
      font-size: 12pt;
    }

    @media (max-width: 350px) {
      font-size: 10pt;
    }
  }
}

#about {
  padding: 6rem 0;

  .container {
    max-width: 1320px;

    @media (min-width: 1400px) {
      margin: 0 auto;
    }

    .title {
      margin-bottom: 6rem;
    }
  }

  .content {
    justify-content: center;

    @media (max-width: 1285px) {
      flex-direction: column;
    }

    #video-slider {
      border-radius: 16px;
      position: relative;
      width: 50%;
      height: 300px;
      overflow: hidden;
      height: fit-content;

      @media (max-width: 1285px) {
        border-radius: 0px;
        width: 100%;
        max-width: 650px;
        height: auto;
      }

      .swiper-button-prev {
        padding: 5px;
        opacity: 85%;
        border-radius: 100%;
        background-color: $secondaryColor-300;
        background-image: url("/wp-content/themes/clenmeUp/assets/images/flecha-esquerda.png");
        background-repeat: no-repeat;
        background-size: 50% auto;
        background-position: center;
        width: 45px;
        height: 45px;
        transition: all 0.5s;
      }

      .swiper-button-prev:hover {
        opacity: 100%;
      }

      .swiper-button-prev:after {
        display: none;
      }

      .swiper-button-next {
        padding: 5px;
        opacity: 85%;
        border-radius: 100%;
        background-color: $secondaryColor-300;
        background-image: url("/wp-content/themes/clenmeUp/assets/images/flecha-direita.png");
        background-repeat: no-repeat;
        background-size: 50% auto;
        background-position: center;
        width: 45px;
        height: 45px;
        transition: all 0.5s;
      }

      .swiper-button-next:hover {
        opacity: 100%;
      }

      .swiper-button-next:after {
        display: none;
      }

      .swiper-wrapper {
        .swiper-slide {
          .video {
            width: 100%;
            height: 350px;

            @media (min-width: 1285px) {
              margin-right: 2.1875rem;
            }

            @media (max-width: 1285px) {
              flex-grow: 0;
              margin: auto;
              max-width: 650px;
              height: auto;
              min-width: auto;
              height: 350px;
              margin-bottom: 2.1875rem;
            }

            &-image {
              img {
                width: 100%;
              }
            }

            &-player {
              width: 100%;
              height: 100%;

              position: absolute;
              top: 0;
              left: 0;

              .player {
                width: 183px;
                height: 183px;

                border: 26px solid $secondaryColor-300;
                border-radius: 50%;

                background: $secondaryColor-700;

                position: relative;

                cursor: pointer;

                i {
                  -webkit-transform: translate(-45%, -45%);
                  transform: translate(-45%, -45%);
                  position: absolute;
                  top: 50%;
                  left: 50%;

                  font-size: 5rem;
                  color: $neutralColor-000;
                }
              }
            }
          }
        }
      }
    }

    .list {
      justify-content: center;
      margin: 0px 25px;

      @media (min-width: 1285px) {
        width: 50%;
      }

      &-widget {
        margin: 0.5rem 0;

        @media (max-width: 1285px) {
          justify-content: center;
        }
      }

      &-text {
        color: $neutralColor-800;
      }

      &-icon {
        color: $secondaryColor-700;
        font-size: 20px;
        margin-right: 1rem;
      }
    }
  }
}

.dataLine {
  padding: 2.875rem 0;
  background: $secondaryColor-500;

  color: $neutralColor-000;

  .container {
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media (min-width: 1320px) {
      max-width: 1320px;
      margin: 0 auto;
    }

    .dataLine-widget {
      margin: 10px;
      width: 160px;
    }
  }

  &-widget {
    > div {
      text-align: center;
    }
  }
}

#services {
  overflow: hidden;
  padding: 6rem 0;

  .container {
    .title {
      margin-bottom: 6rem;
    }

    .content {
      max-width: 1320px;
      margin: 0 auto;

      @media (max-width: 1050px) {
        flex-direction: column;
      }

      > div {
        flex: 1;

        @media (max-width: 536px) {
          flex: 0;
        }

        padding: 4.25rem 3rem;
        border-radius: 16px;

        text-align: center;

        > div {
          margin: 1.5rem auto;
        }
      }

      .service-modal-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        @media (max-width: 467px) {
          flex-direction: column;
          gap: 10px;
          font-size: 20px;
          margin-bottom: 1.5rem;
        }
      }
      .service-modal-content {
        max-height: 300px; 
        overflow: scroll;
      }

      .card {
        background: $neutralColor-000;
        border: 1px solid $neutralColor-100;
        //height: 95%;
        height: auto;
        width: calc(100% / 3);
        min-width: 300px;
        padding: 20px;
        margin-top: 40px;
        align-items: center;
        display: flex; /* Use flex layout for card content */
        flex-direction: column; /* Stack items vertically inside the card */
        transition: all ease-in-out 150ms;

        @media (min-width: 1050px) {
          margin-left: 1.875rem;
        }

        @media (max-width: 605px) {
          height: auto;
          width: 100%;
        }

        @media (max-width: 267px) {
          width: 220px;
          padding: 20px;
        }

        &-title {
          color: $secondaryColor-700;
          margin: 0px;
          font-family: "comfortaa";
          font-weight: 400;
          font-size: 1.075rem;
          letter-spacing: 0px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &-text {
          color: $neutralColor-500;
          font-family: "poppins";
          font-weight: 400;
          font-size: 0.775rem;
          letter-spacing: 0.25px;
          margin: 0px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          line-clamp: 10;
          -webkit-box-orient: vertical;
        }

        &-icon {
          margin: 20px;
        }
        &:hover {
          background: $secondaryColor-400;
          cursor: pointer;
          .card-title {
            color: $neutralColor-000;
          }
          .card-icon-image {
            filter: brightness(0) grayscale(1) invert(1);
          }
        }
      }

      .register {
        background: $secondaryColor-400;
        height: 598px;
        width: 100%;

        @media (min-width: 1050px) {
          margin-right: 1.875rem;
        }

        @media (max-width: 1050px) {
          margin-bottom: 3.75rem;
        }

        @media (max-width: 615px) {
          height: auto;
          width: 100%;
        }

        @media (max-width: 267px) {
          width: 220px;
          padding: 20px;
        }

        color: $neutralColor-000;
      }

      .rent {
        background: $neutralColor-000;
        border: 1px solid $neutralColor-100;
        height: 598px;
        width: 100%;

        @media (min-width: 1050px) {
          margin-left: 1.875rem;
        }

        @media (max-width: 605px) {
          height: auto;
          width: 100%;
        }

        @media (max-width: 267px) {
          width: 220px;
          padding: 20px;
        }

        &-title {
          color: $secondaryColor-700;
        }

        &-text {
          color: $neutralColor-500;
        }
      }
    }
  }
}

#questions {
  padding-top: 6rem;

  .container {
    .title {
      margin-bottom: 3rem;
    }
  }

  .questions {
    &-area {
      max-width: 1320px;
      margin: 0 auto;
      width: 100%;

      @media (max-width: 950px) {
        margin: 0 1%;
      }
    }

    .hidden {
      max-height: 100px;

      @media (max-width: 766px) {
        max-height: 130px;
      }

      @media (max-width: 450px) {
        max-height: 150px;
      }

      @media (max-width: 418px) {
        max-height: 190px;
      }

      @media (max-width: 328px) {
        max-height: 245px;
      }
    }

    &-widget {
      padding: 2rem;
      background: $secondaryColor-100;
      border-radius: 16px;
      height: auto;
      max-height: 500px;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
      margin: 0.5rem 0;

      @media (max-width: 328px) {
        max-height: 550px;
      }

      &-text {
        font-family: "comfortaa";
        font-size: 16px;
        margin-top: 2.2625rem;
      }

      &-head {
        align-items: center;

        i {
          color: $secondaryColor-700;
          font-size: 1.5rem;
        }

        .arrow {
          cursor: pointer;
        }

        div {
          margin-left: 1rem;
          width: 100%;
        }
      }
    }
  }
}

#contact {
  padding: 6rem 0;
  justify-content: center;

  .container {
    max-width: 1320px;

    .content {
      margin-top: 3em;

      .contact {
        &-modes {
          &-title {
            color: $secondaryColor-700;
            padding: 0 0 2rem 0;
          }
        }

        &-mode {
          width: 100%;
          justify-content: space-between;
          padding: 1rem;
          border-top: 1px solid $secondaryColor-400;
          border-bottom: 1px solid $secondaryColor-400;
          position: relative;

          & > i {
            color: $secondaryColor-700;
            font-size: 1rem;
          }

          & > div {
            color: $neutralColor-900;
          }

          &-data {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            cursor: pointer;
          }
        }

        &-mode + div {
          border-top: none;
        }

        &-form {
          width: 100%;

          & form {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        &-info {
          justify-content: center;

          & > div + div {
            margin-top: 1rem;
          }
        }
      }

      & > div + div {
        margin: 0 0 0 2rem;
      }

      @media (max-width: 1280px) {
        flex-direction: column-reverse;
        align-items: center;

        & > div + div {
          margin: 0 0 2rem 0;
        }
      }
    }

    .icon-content {
      display: flex;
      min-width: 5rem;
      height: 5rem;
      border-radius: 16px;
      align-items: center;
      justify-content: center;
      background-color: $secondaryColor-100;
      margin-right: 1.5rem;

      i {
        font-size: 1.5rem;
        color: $secondaryColor-700;
      }
    }

    .location {
      text-align: start;
      justify-content: center;
    }

    .contact-title {
      color: $secondaryColor-700;
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    .contact-text {
      color: $neutralColor-500;
    }
  }
}

#evaluations {
  padding: 6rem 0;
  overflow: hidden;

  #swiper {
    @media (max-width: 1100px) {
      display: none;
    }
  }

  #swiper-mobile {
    @media (min-width: 1100px) {
      display: none;
    }
  }

  & .container {
    max-width: 1320px;
    margin: 0 96px;

    .title {
      margin-bottom: 6rem;
    }

    @media (max-width: 768px) {
      margin: 0 auto;
    }

    @media (min-width: 1320px) {
      margin: 0 auto;
    }

    overflow: hidden;

    & .content {
      flex-direction: column;

      & .swiper {
        width: 100%;

        .swiper-wrapper {
          .swiper-slide {
            user-select: none;
            display: flex;
            justify-content: center;
          }
        }

        /*         & .swiper-wrapper {
          
        } */
      }

      // > div {
      //   flex: 1;

      //   padding: 1.5rem;
      //   border-radius: 16px;

      //   text-align: center;
      // }

      .card-content {
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        width: fit-content;

        .card-description {
          width: 288px;
          min-height: 111px;
          background: $secondaryColor-100;
          border-radius: 1rem;
          text-align: start;
          padding: 1.5rem;
        }
      }

      .card-text {
        color: $neutralColor-800;
      }

      .svg-content {
        margin-right: 0.5rem;

        & > svg {
          width: 0.6875rem;
          height: 1rem;

          & > path {
            fill: $secondaryColor-400;
          }
        }
      }

      .evaluator-content {
        align-items: center;

        .avatar {
          border-radius: 50%;
          height: 72px;
          width: 72px;
          margin: 1rem 0 1rem 0;
        }

        .evaluator-title {
          color: $neutralColor-900;
          margin-bottom: 0.25rem;
        }

        .evaluator-office {
          color: $neutralColor-500;
        }
      }
    }
  }
}

#footer {
  padding: 6rem 0;
  background-color: $neutralColor-100;
  justify-content: center;

  .container {
    max-width: 1320px;
    margin: 0 96px;

    @media (max-width: 500px) {
      margin: 0 30px;
    }

    .divider {
      height: 0.1em;
      background-color: $neutralColor-400;
      margin-bottom: 6rem;
    }

    .copyright {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & .image {
        & > img {
          width: 150px;
        }
      }
    }

    .content {
      justify-content: space-evenly;

      @media (max-width: 1140px) {
        flex-direction: column;
        align-items: center;
      }

      > div {
        flex: 1;
        margin-bottom: 3.75rem;
        border-radius: 16px;
        text-align: center;
      }

      ul {
        list-style-type: none;

        i {
          color: $secondaryColor-700;
        }

        li {
          margin-bottom: 1rem;

          a {
            color: inherit;
          }
        }
      }

      .icons-container {
        @media (max-width: 1140px) {
          justify-content: space-around;
        }
      }

      .pages-text {
        @media (max-width: 1140px) {
          text-align: center;
        }

        color: $neutralColor-500;
      }

      .modal-footer {
        cursor: pointer;
      }

      .list-container {
        @media (max-width: 1140px) {
          justify-content: center;
        }
      }

      .list-content {
        text-align: start;

        .list-title {
          margin-bottom: 1.5rem;
        }
      }

      .logo {
        width: 288px;
        margin-bottom: 1.5rem;

        @media (max-width: 480px) {
          width: 188px;
        }
      }

      .location-text {
        color: $neutralColor-500;
        margin-bottom: 1.5rem;
      }

      .location-content {
        text-align: start;
      }

      .sn-content {
        flex: 2;
        text-align: start;
        min-width: 28%;

        .text-description {
          margin-bottom: 1.563rem;
        }

        i {
          color: $neutralColor-700;
          font-size: 16px;
          margin-right: 1.875rem;

          @media (max-width: 1140px) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50000;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background: rgba($neutralColor-700, $alpha: 0.4);

  & > div {
    width: 4rem;
    height: 4rem;

    border: 6px solid transparent;
    border-top-color: $secondaryColor-600;
    border-left-color: $secondaryColor-600;
    border-bottom-color: $secondaryColor-600;
    border-radius: 50%;

    animation: loading 1s ease-in-out infinite;
  }
}

#dynamic-age {
  width: 100%;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;

  & span {
    width: 30%;
    margin-bottom: 1rem;

    & select {
      width: 100%;
    }
  }
}

#dynamic-age-label {
  width: 100%;
  text-align: left;
}
