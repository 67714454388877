// Colors
$primaryColor-100: #D7FFFF;
$primaryColor-200: #BCFFFF;
$primaryColor-300: #A1FFFF;
$primaryColor-400: #78FFFF;
$primaryColor-500: #42FFFF;
$primaryColor-600: #00E6E6;
$primaryColor-700: #00CFCF;

$secondaryColor-100: #D4E8FF;
$secondaryColor-200: #AAD2FF;
$secondaryColor-300: #7FBBFF;
$secondaryColor-400: #63ACFF;
$secondaryColor-500: #3695FF;
$secondaryColor-600: #0C7DFF;
$secondaryColor-700: #0072F4;

$tertiaryColor-500: #0040BB;
$tertiaryColor-700: #002670;

$neutralColor-000: #FFF;
$neutralColor-100: #F4F4F8;
$neutralColor-200: #E3E6E9;
$neutralColor-300: #CBD5E1;
$neutralColor-400: #94A3B8;
$neutralColor-500: #64748B;
$neutralColor-600: #505D6F;
$neutralColor-700: #3C4653;
$neutralColor-800: #282E38;
$neutralColor-900: #14171C;

$green: #28A745;
$red: #DC3545;
$yellow: #FFC107;
$blue: #17A2B8;

// Typography
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;