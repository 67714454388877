// Import Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Import Comfortaa
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@import 'variables.scss';
@import 'class.scss';

@import 'layout/header.scss';
@import 'layout/modal.scss';

@import 'pages/home.scss';

* {
    margin: 0;
    padding: 0;
    font-size: 16px;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.container {
    flex: 1;
    margin: 0 96px;
    
    @media (max-width: 605px) {
        margin: 0 5%;
    }
}

.flex {
    display: flex;
    &-center {
        justify-content: center;
        align-items: center;
    }
    &-row {
        flex-direction: row;
        &-space {
            &-between {
                justify-content: space-between;
            }
        }
    }
    &-wrap { 
        flex-wrap: wrap;
    }
    &-column { 
        flex-direction: column;
        
    }
    &-column-form {
        @media (max-width: 901px) {
            flex-direction: column;
        }
    }
    &-1 { flex: 1; }
    &-2 { flex: 2; }

    &-align {
        &-center {
            align-items: center;
        }
    }
}

.hide {
    display: none !important;
}

section {
    .title {
        margin: 0 107px;
        align-items: center;
        text-align: center;
        max-width: 1320px;
        margin: 0 auto;

        @media (max-width: 605px) {
            margin: 0;
        }

    }
}