@import "variables.scss";

@font-face {
    font-family: "bootstrap-icons";
    src: url("wp-content/themes/clenmeUp/node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2?524846017b983fc8ded9325d94ed40f3") format("woff2"),
        url("wp-content/themes/clenmeUp/node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff?524846017b983fc8ded9325d94ed40f3") format("woff");
}

.headline {
  &-1 {
    font-family: "comfortaa";
    font-weight: $font-light;
    font-size: 5.8125rem;
    letter-spacing: -1.5px;
  }

  &-2 {
    font-family: "comfortaa";
    font-weight: $font-light;
    font-size: 3.625rem;
    letter-spacing: -0.5px;
  }

  &-3 {
    font-family: "comfortaa";
    font-weight: $font-regular;
    font-size: 2.875rem;
    letter-spacing: 0px;
  }

  &-4 {
    font-family: "comfortaa";
    font-weight: $font-regular;
    font-size: 2.0625rem;
    letter-spacing: 0.25px;
  }

  &-5 {
    font-family: "comfortaa";
    font-weight: $font-regular;
    font-size: 1.4375rem;
    letter-spacing: 0px;
  }

  &-6 {
    font-family: "comfortaa";
    font-weight: $font-medium;
    font-size: 1.1875rem;
    letter-spacing: 0.15px;
  }
}

.title-error {
  color: tomato !important;
}

.subtitle {
  &-1 {
    font-family: "comfortaa";
    font-weight: $font-regular;
    font-size: 1rem;
    letter-spacing: 0.15px;
  }

  &-2 {
    font-family: "comfortaa";
    font-weight: $font-medium;
    font-size: 0.875rem;
    letter-spacing: 0.1px;
  }
}

.body {
  &-1 {
    font-family: "poppins";
    font-weight: $font-regular;
    font-size: 1rem;
    letter-spacing: 0.15px;
  }

  &-2 {
    font-family: "poppins";
    font-weight: $font-regular;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
  }
}

.overline {
    font-family: 'poppins';
    font-weight: $font-regular;
    font-size: 0.625rem;
    letter-spacing: 1.5px;
}

.caption {
  &-font {
    font-family: "poppins";
    font-weight: $font-regular;
    line-height: 18px;
    font-size: 0.75rem;
    letter-spacing: 0.4px;
    color: $neutralColor-500;
  }
}

.shadow {
  &-md {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  &-sm {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  &-lg {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  }
}

.backgroundImage {
  position: relative;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    background-image: url("../../assets/images/hero.jpeg");
    background-repeat: no-repeat;
    background-size: cover;

    opacity: 0.4;
  }
}

.button {
  padding: 0.8125rem 1rem;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  color: $neutralColor-500;

  transition: all 0.3s;

  font-family: "poppins";
  font-weight: $font-medium;
  font-size: 0.875rem;
  letter-spacing: 1.25px;
  min-height: 100%;

  &-contained {
    background-color: $secondaryColor-700;
    color: $neutralColor-000;

    &:hover {
      background-color: $secondaryColor-500;
    }

    &-onclick {
      background: $secondaryColor-400 !important;
    }
  }

  &-outlined {
    background: $neutralColor-000;
    color: $secondaryColor-700;
    border: 1px solid $secondaryColor-700;

    &:hover {
      color: $secondaryColor-500;
      border: 1px solid $secondaryColor-500;
    }

    &-onclick {
      color: $secondaryColor-400 !important;
      border: 1px solid $secondaryColor-400 !important;
    }
  }

  &-text {
    background: $neutralColor-000;
    color: $secondaryColor-700;

    &:hover {
      color: $secondaryColor-500;
    }

    &-onclick {
      color: $secondaryColor-400 !important;
    }
  }

  &-transparent {
    background: transparent !important;
  }

  &-delete {
    color: $red;
  }
}

.section {
  &-title {
    color: $secondaryColor-700;
    margin-bottom: 1rem;
  }

  &-subtitle {
    color: $neutralColor-500;
    text-align: center;
  }
}

.input {
    margin: 1rem 0 0;

    &-title {
        color: $neutralColor-800;
    }

    &-labelOnly {
        flex: 1;
    
        & label {
          margin-bottom: 0.5rem;
          &.error {
            color: $red;
          }
        }

        & input[type="text"],
        & input[type="email"] {
          &.error {
            outline: 2px solid $red;
          }
        }  
    }

    &-error-msg {
      padding: 0.25rem 1rem;
      color: $red;
    }

    &-titleWithcaption {
        gap: 0.5rem;
        
        & > .caption {
          font-family: "poppins";
          font-weight: $font-regular;
          line-height: 18px;
          font-size: 0.75rem;
          letter-spacing: 0.4px;
          color: $neutralColor-800;
        }
    }

    &-checkbox {
        
        & label {
            color: $neutralColor-900;
            line-height: 1.8rem;
            margin-left: .5rem;
        }
    
        & input[type=checkbox] {
            appearance: none;
            background-color: $neutralColor-100;
            
            position: relative;
            cursor: pointer;
            margin-right: .5rem;
    
            &:hover {
                &::before {
                    border-color: $neutralColor-600;
                }
            }
    
            &::before {
                content: "";
                display: block;
                position: relative;
                width: 1.5rem;
                height: 1.5rem;
                top: 0;
                left: 0;
                border: 1.5px solid $neutralColor-400;
                border-radius: 3px;
                background-color: $neutralColor-000;
            }
    
            &:checked {
                &:hover{
                    &::before {
                        background: $tertiaryColor-700;
                    }
                }
                
                &::before {
                    background: $tertiaryColor-500;
                }
    
                &:after {
                    content: "";
                    display: block;
                    width: 7px;
                    height: 14px;
                    border: solid $neutralColor-000;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 3px;
                    left: 9px;
                }
            }
        }
    }

    &-radio {
        align-items: center;

        & label {
            color: $neutralColor-900;
        }
    }

    &-file {
        flex: 1;
        align-items: center;
        justify-content: space-between;

        input[type="file"] {
            display: none;
        }

        & span {
          display: none;
        }

        &-title {
            color: $neutralColor-800;
        }
    }

    &-select {
        width: 100%;

        align-items: center;
        justify-content: space-between;
    }

    &-checkboxAndLabel {
        flex: 1;
        align-items: center;

        .input {
            flex: 1;
            justify-content: space-between;
        
            .input-onlyLabel {
                max-width: 470px;
                width: 100%;
            }
        }
    }

    &-checkbox2 {

        &-label {
            color: $neutralColor-800;
        }

        &-area {
            flex-wrap: wrap;

            &-input {
                margin: 1rem 5px !important; 
                flex: 1;
                max-width: 100%;

                position: relative;

                line-height: 1.5rem;

                padding: 0.5rem 1rem;
                background: $neutralColor-000;
                border: 1px solid $neutralColor-400;
                border-radius: 8px;

                &-noFlex {
                    flex: none !important;
                }

                &-check {
                    border: 2px solid $blue !important;
                }

                & *,
                & {
                    cursor: pointer;   
                }

                & label {
                    flex: 1;
                    margin-left: 2rem;
                    text-align: left;
             
                    color: $neutralColor-600;
                }
            }
        }

        & input[type=checkbox] {
            appearance: none;
            background-color: $neutralColor-100;
            
            position: absolute;
            top: 0.5rem;
            left: 1rem;
            
            &:hover {
                &::before {
                    border-color: $neutralColor-600;
                }
            }

            &::before,
            &:after {
                font-family: "bootstrap-icons" !important;
                
                font-size: 1.6rem;
                top: -0.1rem;
                left: 0px;
            }

            &::before {
                content: "\f4fa";
                color: $neutralColor-400;
                display: block;
                position: relative;
                border-radius: 50%;
            }

            &:checked {
                &::before {
                    border: none;
                }
    
                &:after {
                    content: "\f26a";
                    color: $secondaryColor-500;
                    position: absolute;
                }
            }
        }
    }

    &-labelOnly + &-select {
      margin: 0 0 0 2rem;
    }

    input[type="radio"] {
        appearance: none;
        background-color: $neutralColor-100;
        margin: 0;
        font: inherit;
        color: $neutralColor-400;
        width: 1rem;
        height: 1rem;
        border: 1.5px solid $neutralColor-400;
        border-radius: 50%;
        cursor: pointer;
        transform: translateY(-0.075em);
        margin-right: .5rem;
        display: grid;
        place-content: center;

        &:hover {
            border-color: $neutralColor-600;
        }

        &::before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $tertiaryColor-500;
        }

        &:checked {
            transform: scale(1);
            border-color: $tertiaryColor-500;

            &::before {
                transform: scale(1);
            }

            &:hover {
                border-color: $tertiaryColor-700;
            
                &::before {
                    box-shadow: inset 1em 1em $tertiaryColor-700;
                }
            }
        }
    }

    textarea,
    input[type="text"],
    input[type="email"] {
        border: none;

        border: 1px solid $neutralColor-400;
        color: $neutralColor-900;
        padding: 0.5rem 1rem;
        border-radius: 8px;

        width: 100%;

        &::placeholder {
            color: $neutralColor-600;
        }

        &:focus {
            border: none;
            outline: 2px solid $blue;
        }
    }

    select {
        outline: none;
        width: 245px;
        border: none;

        border: 1px solid $neutralColor-400;
        padding: 0.5rem 1rem;
        border-radius: 8px;

        background: $neutralColor-000;
        color: $neutralColor-600;

        option {
            color: $neutralColor-900;
        }

        &:focus {
            border: none;
            outline: 2px solid $blue;
        }
    }

    & > &-radio + &-radio,
    & > &-labelOnly + &-labelOnly,
    & > &-checkbox + &-checkbox,
    & > &-onlyLabel + &-onlyLabel {
      margin-left: 2rem;
    }
}

#modal-clean .modal-clean-tab[data-id="0"] {
  & > div + div {
    margin-left: 2rem;
  }
}

#modal-complete {
  & .modal-area {
    & > div {
      width: 100%;
    }
  }
}

.modal-complete {
  &-close {
    justify-content: flex-end;

    & > i {
      color: $secondaryColor-700;
      font-size: 2rem;
      cursor: pointer;
    }
  }

  &-image {
    max-width: 100%;
    width: 16.5rem;
    height: 16.5rem;
    justify-content: center;
    align-items: center;
  }

  &-text {
    color: $secondaryColor-700;
    text-align: center;
  }

  &-button {
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .input,
  .input-select,
  #modal-clean .modal-clean-tab[data-id="0"],
  .input-file {
    flex-direction: column;
  }

  #modal-clean .modal-clean-tab[data-id="0"] {
    & > div + div {
      margin: 0;
    }
  }

  .input {
    &-select {
      align-items: flex-start;
      justify-content: center;

      & > label {
        margin-bottom: 1rem;
      }

      & span {
        &,
        & select {
          width: 100%;
        }
      }
    }
  
    &-file {
      align-items: flex-start;
      justify-content: center;
    }

    & > &-radio + &-radio,
    & > &-labelOnly + &-labelOnly,
    & > &-checkbox + &-checkbox,
    & > &-onlyLabel + &-onlyLabel {
      margin: 0;
      margin-top: 1rem;
    }

    &-labelOnly + &-select {
      margin: 1rem 0 0 0;
    }
  }
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 14px;
  }
}