@import '../variables.scss';

.header {
    background: $neutralColor-000;
    width: 100vw;
    height: 96px;
    position: fixed;
    top: 0;
    left: 0;
    transition: box-shadow 0.3s;
    z-index: 1000;

    .container {
        justify-content: space-between;
        align-items: center;
        max-width: 1320px;
        margin: 0 96px;

        @media (max-width: 700px) {
            margin: 0 30px;
        }
    }

    .logo {
        width: 200px;

        @media (max-width: 480px) {
            width: 188px;
        }

        @media (max-width: 320px) {
            width: 128px;
        }

    }
}

#hamburger {
    cursor: pointer;
    font-size: 25pt;
    color: $blue;

    @media (min-width: 931px) {
        display: none;
    }

    @media (max-width: 320px) {
        font-size: 18pt;
    }
}

.menu {
    display: flex;

    @media (max-width:930px) {
        display: none;
    }

    ul {
        li {
            list-style: none;

            a {
                color: $neutralColor-500;

                &:hover,
                &[selected="selected"] {
                    color: $primaryColor-700;
                }
            }
        }

        li+li {
            margin-left: 32px;
        }
    }
}

#dropdown-languages {
    position: relative;
    display: inline-block;

    #languages {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: max-content;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 22px 28px;
        border-radius: 10px;
        z-index: 1;
        transform: translateX(calc(3em - 100%));
        animation: appear .2s;

        li {
            padding: 10px;
        }
    }
}

#dropdown-languages:hover #languages {
    display: block;
}

.lang-item {
    margin: 0 !important;
    padding-top: 5px;

    a {
        cursor: pointer;
    }
}