@import '../variables.scss';
@import '../class.scss';

.modal {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($neutralColor-700, $alpha: 0.4);
    animation: appear .5s;

    &-area {
        max-width: 885px;
        width: 95%;
        max-height: 90vh;
        
        padding: 3.125rem 2rem 2rem;
        
        background: $neutralColor-000;
        
        border-radius: 24px;
        
        @media (max-width: 350px) {
            padding: 1.725rem 1.5rem 1.5rem;
        }
    }

    &-title {
        color: $secondaryColor-700;
        text-align: center;
        margin-bottom: 3.5rem;
    }

    &-tab {
        width: 100%;
        justify-content: center;
        align-items: center;

        margin-bottom: 3.5rem;

        &-indicator {
            width:3.5rem;
            height:3.5rem;
            background: $neutralColor-200;
            border-radius: 50%;
            
            line-height:3.5rem;
            text-align: center;
            color: $neutralColor-400;
        
            &[active="active"] {
                color: $secondaryColor-700;
                background: $secondaryColor-100;
            }
        }

        &-line {
            flex: 1;
            border: 1px solid $neutralColor-400;
        
            &[active="active"] {
                border-color: $secondaryColor-500;
            }
        }
    }

    &-form {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        & > div {
            display: flex;
            width: 100%;
        }

        form {
            flex: 1;
        
            > div {
                flex: 1;
                gap: 2rem;
            }
        }

        &-area {
            margin-bottom: 2rem;
            padding: 1.5rem;
            border-radius: 16px;
            background-color: $neutralColor-100;
        }
    }

    &-buttons {
        gap: 1rem;
        justify-content: flex-end;

        margin-top: 1.5rem;
        height: 51px;
    }
}

@keyframes appear {
    from {
      opacity: 0;
    }
}

.wpcf7-list-item-label {
    color: $neutralColor-900;
    
    font-family: "poppins";
    font-weight: $font-regular;
    font-size: 0.875rem;
    letter-spacing: 0.25px;

    line-height: 200%;
}

.wpcf7-list-item {
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;

    gap: 0.5rem;
}

#cf7sg-form-modal-rent-en,
#cf7sg-form-modal-rent-es,
#cf7sg-form-modal-rent-2,
#cf7sg-form-modal-babysis-pt,
#cf7sg-form-modal-babysis-en,
#cf7sg-form-modal-babysis-es,
#cf7sg-form-modal-clean-en,
#cf7sg-form-modal-clean-es,
#cf7sg-form-modal-clean-pt,
#cf7sg-form-modal-provide-pt,
#cf7sg-form-modal-provide-en,
#cf7sg-form-modal-provide-es {
    width: 100%;
}

.uacf7-form-184,
.uacf7-form-182,
.uacf7-form-187,
.uacf7-form-189,
.uacf7-form-191,
.uacf7-form-198,
.uacf7-form-201,
.uacf7-form-207,
.uacf7-form-250,
.uacf7-form-256,
.uacf7-form-253,
.uacf7-form-258 {
    & > div {
    }
}
